import { DocumentProperties, jsPDF } from 'jspdf';
import {
  redrawImage,
  imageDimensionsOnA4,
  FAILED_TO_LOAD_IMAGE_STRING,
  PerPage,
} from './image';
import { ImageContainerType } from './ImageJoiner.types';

const TO_PX_RATIO = 3.7795275591;
const SPACE_FROM_BOTTOM = 4;
const PADDING_RECTANGLE = 3;
const FONT_SIZE = 7.5;

const renderImageName = (
  doc: jsPDF,
  fileName: string,
  onPageParams: ReturnType<typeof imageDimensionsOnA4>
) => {
  const textDims = doc.getTextDimensions(fileName, {
    maxWidth: onPageParams.imagePartDimensions.width,
    fontSize: FONT_SIZE,
  });

  const x = onPageParams.x + (onPageParams.width / 2 - textDims.w / 2);
  const y = onPageParams.height + onPageParams.y - SPACE_FROM_BOTTOM;

  const rectangleWidth = Math.min(
    textDims.w + PADDING_RECTANGLE,
    onPageParams.imagePartDimensions.width
  );

  const rectangleX =
    rectangleWidth === onPageParams.imagePartDimensions.width
      ? x
      : x - PADDING_RECTANGLE / 2;

  doc.saveGraphicsState();
  doc.setGState(doc.GState({ opacity: 0.33 }));
  doc.setFillColor('#ffffff');
  doc.setDrawColor('#bbbbbb');
  doc.roundedRect(
    rectangleX,
    y - textDims.h - PADDING_RECTANGLE / 2,
    rectangleWidth,
    textDims.h + PADDING_RECTANGLE,
    2,
    5,
    'FD'
  );
  doc.restoreGraphicsState();
  doc.setTextColor(0, 0, 0);

  doc.text(fileName, x, y - textDims.h, {
    maxWidth: onPageParams.imagePartDimensions.width,
    baseline: 'top',
  });
};

export const exportAsPdf = async (
  images: ImageContainerType[],
  imagesPerPage: PerPage = 1,
  docProperties?: DocumentProperties
): Promise<jsPDF> => {
  const doc = new jsPDF({ compress: true });

  doc.deletePage(1);

  const redrawnImages: {
    origFile: File;
    data: string;
    imgProps: ReturnType<typeof imageDimensionsOnA4>;
  }[] = await Promise.all(
    images
      .filter(image => image.content !== FAILED_TO_LOAD_IMAGE_STRING)
      .map(async (image, index) => {
        const imageProperties = doc.getImageProperties(image.content);
        const imgProps = imageDimensionsOnA4(
          {
            width: imageProperties.width,
            height: imageProperties.height,
          },
          imagesPerPage,
          index % imagesPerPage
        );

        const { width, height } = imgProps;

        const [content] = await redrawImage(image.content, image.mimeType, {
          quality: 0.8,
          rotationDirection: 'none',
          maxCanvasHeight: Math.min(
            2 * height * TO_PX_RATIO,
            imageProperties.height
          ),
          maxCanvasWidth: Math.min(
            2 * width * TO_PX_RATIO,
            imageProperties.width
          ),
        });

        return {
          origFile: image.file,
          data: content,
          imgProps,
        };
      })
  );

  doc.setFontSize(FONT_SIZE);

  redrawnImages.forEach((src, index) => {
    if (index % imagesPerPage === 0) {
      doc.addPage('a4');
    }

    doc.addImage(
      src.data,
      images[index].mimeType,
      src.imgProps.x,
      src.imgProps.y,
      src.imgProps.width,
      src.imgProps.height
    );

    renderImageName(
      doc,
      src.origFile.name.normalize('NFD').replace(/\p{Diacritic}/gu, ''),
      src.imgProps
    );
  });

  if (docProperties) {
    doc.setProperties(docProperties);
  }

  return doc;
};
