export type ImageRotationDirection = 'left' | 'right' | 'none';

type rotateImageOptions = {
  rotationDirection?: ImageRotationDirection;
  quality?: number;
  maxCanvasWidth?: number;
  maxCanvasHeight?: number;
};

export const FAILED_TO_LOAD_IMAGE_STRING = '$$_ERROR_$$';

const convertStringToImage = (image: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const tempImage = new Image();
    tempImage.src = image;
    tempImage.onload = () => resolve(tempImage);
    tempImage.onerror = reject;
  });
};

export type Orientation = 'portrait' | 'landscape';

export const redrawImage = async (
  img: HTMLImageElement | string,
  type: string,
  options?: rotateImageOptions
): Promise<[string, Orientation]> => {
  const canvas = document.createElement('canvas');

  if (typeof img === 'string') {
    img = await convertStringToImage(img);
  }

  const {
    rotationDirection,
    quality = 1,
    maxCanvasWidth = img.naturalWidth,
    maxCanvasHeight = img.naturalHeight,
  } = options || {
    quality: 1,
    rotationDirection: 'none',
  };

  canvas.width =
    rotationDirection === 'none' ? maxCanvasWidth : maxCanvasHeight;
  canvas.height =
    rotationDirection === 'none' ? maxCanvasHeight : maxCanvasWidth;

  const x = canvas.width / 2;
  const y = canvas.height / 2;

  const ctx = canvas.getContext('2d', { alpha: false, desynchronized: true });

  if (ctx) {
    if (type === 'image/png') {
      type = 'image/jpeg';
    }

    ctx.fillStyle = '#fff';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    let angle;

    switch (rotationDirection) {
      case 'left': {
        angle = -90;
        break;
      }
      case 'right': {
        angle = 90;
        break;
      }
      case 'none': {
        angle = 0;
        break;
      }
    }

    if (angle) {
      ctx.translate(x, y);
      ctx.rotate((angle * Math.PI) / 180);
      ctx.translate(-y, -x);
    }

    ctx.drawImage(img, 0, 0, maxCanvasWidth, maxCanvasHeight);
  }

  let orientation: Orientation;

  if (img.naturalWidth > img.naturalHeight) {
    orientation = rotationDirection === 'none' ? 'landscape' : 'portrait';
  } else {
    orientation = rotationDirection === 'none' ? 'portrait' : 'landscape';
  }

  return [canvas.toDataURL(type, quality), orientation];
};

// In mm
const A4_PAPER_DIMENSIONS = {
  width: 210,
  height: 297,
};

interface ImageDimension {
  width: number;
  height: number;
}

export type PerPage = 1 | 2 | 3 | 4;

// Calculates the best possible position of an image on the A4 paper format,
// so that the maximal area of A4 is used and the image ratio is preserved.
export const imageDimensionsOnA4 = (
  dimensions: ImageDimension,
  perPage: PerPage = 1,
  indexOnPage: number
) => {
  if (perPage < 1) {
    perPage = 1;
  } else if (perPage > 4) {
    perPage = 4;
  }

  const isLandscapeImage = dimensions.width >= dimensions.height;
  const imagePartDimensions = { ...A4_PAPER_DIMENSIONS };

  if (perPage === 2 || perPage === 3) {
    imagePartDimensions.height = imagePartDimensions.height / perPage;
  } else if (perPage === 4) {
    imagePartDimensions.width = imagePartDimensions.width / 2;
    imagePartDimensions.height = imagePartDimensions.height / 2;
  }

  const imagePaperRatio =
    imagePartDimensions.width / imagePartDimensions.height;

  // If the image is in portrait and the full height of A4 would skew
  // the image ratio, we scale the image dimensions.
  const imageRatio = dimensions.width / dimensions.height;

  let x, y, width, height;

  // If the image is in landscape, the full width of A4 is used.
  if (isLandscapeImage && imagePaperRatio < 1) {
    width = imagePartDimensions.width;
    height = imagePartDimensions.width / imageRatio;
  } else if (imageRatio > imagePaperRatio) {
    const imageScaleFactor =
      (imagePaperRatio * dimensions.height) / dimensions.width;

    const scaledImageHeight = imagePartDimensions.height * imageScaleFactor;

    height = scaledImageHeight;
    width = scaledImageHeight * imageRatio;
  } else {
    width = imagePartDimensions.height / (dimensions.height / dimensions.width);
    height = imagePartDimensions.height;
  }

  let xCorrection = 0;
  let yCorrection = 0;

  if (perPage === 2 || perPage === 3) {
    yCorrection = indexOnPage * imagePartDimensions.height;
  } else if (perPage === 4) {
    xCorrection = indexOnPage > 1 ? imagePartDimensions.width : 0;
    yCorrection =
      indexOnPage === 1 || indexOnPage === 3 ? imagePartDimensions.height : 0;
  }

  x = xCorrection + (imagePartDimensions.width - width) / 2;
  y = yCorrection + (imagePartDimensions.height - height) / 2;

  height = Math.floor(height);
  width = Math.floor(width);

  return {
    x,
    y,
    width,
    height,
    imagePartDimensions,
  };
};
