import cuid from 'cuid';
import { DocumentProperties } from 'jspdf';
import React, { useEffect, useState } from 'react';
import { insertAt, removeAt, updateAt } from './array';
import {
  FAILED_TO_LOAD_IMAGE_STRING,
  ImageRotationDirection,
  Orientation,
  redrawImage,
} from './image';
import { exportAsPdf } from './pdf';
import { ImageContainerType, ImageToPdfProps } from './ImageJoiner.types';

const readImage = (
  file: ImageContainerType,
  onReadFail?: (e: any) => void
): Promise<[string | undefined, Orientation]> => {
  return new Promise((resolve, reject) => {
    const onLoadReader: FileReader['onload'] = async function(e) {
      if (!e?.target?.result) {
        return reject('');
      }

      try {
        const result = await redrawImage(
          e.target.result as string,
          file.mimeType,
          {
            rotationDirection: 'none',
            quality: 0.8,
          }
        );

        resolve(result);
      } catch (e) {
        if (onReadFail) {
          onReadFail(e);
        }
        reject(e);
      }
    };

    const reader = new FileReader();
    reader.onload = onLoadReader;
    reader.readAsDataURL(file.file);
  });
};

const convertFilesToContainerType = (files: File[]): ImageContainerType[] => {
  return files.map(f => ({
    mimeType: f.type,
    file: f,
    content: '',
    id: cuid(),
    orientation: undefined,
  }));
};

export const ImageToPdf = ({
  children,
  files,
  getDocumentProperties,
  getLoadedImagePosition,
  onReadFail,
  onExportFail,
}: {
  files?: File[];
  getDocumentProperties?: () => DocumentProperties;
  children: (props: ImageToPdfProps) => JSX.Element;
  getLoadedImagePosition?: (
    items: ImageContainerType[],
    file: ImageContainerType
  ) => number;
  onExportFail?: (e: any) => void;
  onReadFail?: (e: any) => void;
}): JSX.Element => {
  const [items, setItems] = useState<ImageContainerType[]>([]);
  const [loadingInProgress, setLoadingInProgress] = useState<number>(0);

  useEffect(() => {
    if (files?.length) {
      handleAddImages(files, false);
    }
  }, []);

  const handleAddImages: ImageToPdfProps['handleAddImages'] = (
    files: File[],
    sort: boolean = true
  ) => {
    const newFiles = convertFilesToContainerType(files);

    setItems(items => {
      let newArray = [...items];

      newFiles.forEach(file => {
        if (sort && getLoadedImagePosition) {
          newArray = insertAt(
            newArray,
            file,
            getLoadedImagePosition(newArray, file)
          );
        } else {
          newArray.push(file);
        }
      });

      return newArray;
    });

    setLoadingInProgress(newFiles.length);

    setTimeout(async () => {
      for (let i = 0; i < newFiles.length; i++) {
        const file = newFiles[i];

        const [newContent, orientation] = await readImage(
          newFiles[i],
          onReadFail
        ).catch(() => {
          return [FAILED_TO_LOAD_IMAGE_STRING, undefined];
        });

        setItems(prevState => {
          const itemIndex = prevState.findIndex(f => f.id === file.id);

          return updateAt(
            prevState,
            { ...prevState[itemIndex], content: newContent, orientation },
            itemIndex
          );
        });
        setLoadingInProgress(prevState => prevState - 1);
      }
    }, 100);
  };

  const handleRemoveImage = (index: number) => {
    setItems(prevItems => removeAt(prevItems, index));
  };

  const handleRotateImage = (
    index: number,
    element: HTMLImageElement,
    direction: ImageRotationDirection
  ) => {
    setLoadingInProgress(prevState => prevState + 1);
    setTimeout(async () => {
      setItems(
        updateAt(
          items,
          {
            file: items[index].file,
            content: '',
          },
          index
        )
      );

      setTimeout(async () => {
        const [content, orientation] = await redrawImage(
          element,
          items[index].mimeType,
          {
            rotationDirection: direction,
          }
        );

        setItems(
          updateAt(
            items,
            {
              file: items[index].file,
              content,
              orientation,
            },
            index
          )
        );
      }, 42);
      setLoadingInProgress(prevState => prevState - 1);
    }, 42);
  };

  const handleExportAsPdf = async (perPage?: 1 | 2 | 3 | 4) => {
    try {
      return exportAsPdf(
        items,
        perPage,
        getDocumentProperties ? getDocumentProperties() : undefined
      );
    } catch (e) {
      if (onExportFail) {
        onExportFail(e);
      }

      throw e;
    }
  };

  return React.createElement(children, {
    items,
    setItems,
    loadingInProgress: loadingInProgress > 0,
    merge: handleExportAsPdf,
    handleRemoveImage,
    handleRotateImage,
    handleAddImages,
  });
};
