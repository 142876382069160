export function isNonEmptyArray(arr: any[]): boolean {
  return arr && Array.isArray(arr) && arr.length > 0;
}

export function nullOrEmptyArray(arr: any[]): boolean {
  return !isNonEmptyArray(arr);
}

export function insertAt<T>(arr: T[], item: T, index: number): T[] {
  if (nullOrEmptyArray(arr)) {
    arr = [];
  }

  return [...arr.slice(0, index), item, ...arr.slice(index)];
}

export function removeAt<T>(arr: T[], index: number): T[] {
  if (nullOrEmptyArray(arr)) {
    arr = [];
  }

  return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

export function updateAt<T>(
  arr: T[],
  valuesToUpdate: { [key: string]: any },
  index: number
): T[] {
  if (nullOrEmptyArray(arr)) {
    arr = [];
  }

  const objectToUpdate = arr[index];
  const arrayStart = arr.slice(0, index);
  const arrayEnd = arr.slice(index + 1);
  return [...arrayStart, { ...objectToUpdate, ...valuesToUpdate }, ...arrayEnd];
}
